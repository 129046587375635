export const IN_STOCK = "in-stock";

//cloudfront repositories
export const IMAGES_URL = "https://d1rhoi6onixddn.cloudfront.net/website-images/";
export const GAME_IMAGES_URL = "https://d1rhoi6onixddn.cloudfront.net/game-images/";
export const VIDEOS_URL = "https://d1rhoi6onixddn.cloudfront.net/website-videos/";
export const DOCS_URL = "https://d1rhoi6onixddn.cloudfront.net/docs/";
export const SOFTWARE_URL = "https://d2mzlempwep3hb.cloudfront.net/TrackIR5/";
export const SDK_URL = "https://d2mzlempwep3hb.cloudfront.net/TrackIR_SDK/";
export const MEDIAKIT_URL = "https://d1rhoi6onixddn.cloudfront.net/mediakit/";

// Phone Numbers
export const TOLL_FREE_PHONE = "1-888-965-0435";
export const INTERNATIONAL_CALLERS = "1-541-753-6645";
export const NP_PHONE_NUMBER = "1-888-865-5535";
export const OPTITRACK_PHONE_NUMBER = "1-888-965-0435";
export const TRACKIR_PHONE_NUMBER = "1-888-965-0436";
export const SMARTNAV_PHONE_NUMBER = "1-888-965-0437";
export const FAX_NUMBER = "1-541-753-6689";
