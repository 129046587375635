import Vue from 'vue';
import * as types from "../../mutation-types";

const state = {
  /* Tracks which game is open in the game list */
  displayedGame: null,
  games: []
};

const getters = {
  getDisplayedGame: state => {
    return state.displayedGame;
  }
};

const mutations = {
  [types.SET_DISPLAYED_GAME](state, data) {
    state.displayedGame = data;
  },
  [types.SET_GAMES](state, data) {
    state.games = data;
  }
};

const actions = {
  getVailableGames: ({ commit, dispatch }) => {
    Vue.axios
      .get("games/json?webonly=true")
      .then((response) => {
        commit(types.SET_GAMES, response.data)
      })
      .catch((error) => {})
  }
}

const module = {
  state,
  getters,
  mutations,
  actions
};

export default module;
