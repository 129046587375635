<template>
  <div id="app">
    <Header />
    <div class="content">
      <!--<transition-fade>-->
      <router-view />
      <!--</transition-fade>-->
    </div>
    <!--END content -->
    <Footer />
  </div>
</template>
<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "App",
  components: {
    Header,
    Footer,
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";

html,
body {
  height: 100%;
  padding: 0px;
  margin: 0px;
}

body {
  background: var(--color-bg-solid);
  background: linear-gradient(
      to left,
      var(--color-bg-linear1),
      var(--color-bg-linear2) 10%,
      var(--color-bg-linear3) 20%,
      var(--color-bg-linear3) 80%,
      var(--color-bg-linear2) 90%,
      var(--color-bg-linear1)
    ),
    radial-gradient(
      farthest-side,
      var(--color-bg-linear3) 0,
      var(--color-bg-linear3) 60%,
      var(--color-bg-linear2) 80%,
      var(--color-bg-linear1) 100%,
      var(--color-bg-linear4) 135%
    );
  background-position: left 0 top 12050px, top;
  background-repeat: no-repeat;
  background-size: auto 10000px, 100% 12000px;
  font-size: $body_font_size;
  color: var(--color-text-default);
  font-family: $font-stack-default;
  line-height: $body_line_height;
}
#app {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.content {
  flex: 1 0 auto;
}

.trackir-icons {
  font-family: "trackir-icons";
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #ffffff;
  font-weight: 400;
  font-family: $font-stack-heading;
}
h1,
h2 {
  text-transform: uppercase;
}
h1 {
  font-size: 45px;
}
h2 {
  font-size: 36px;
}
a {
  text-decoration: none;
  color: var(--color-text-default);
}
.contentWrapper {
  @include adaptiveContainer();
}
.contentSpacing {
  padding: 65px 0;
}
.dimBG {
  background: rgba(0, 0, 0, 0.1);
}
.darkBG {
  background: linear-gradient(to bottom, #161616 0, #232323);
}
.btn {
  @include btn();
}
.grecaptcha-badge { 
  visibility: hidden; 
}
</style>
