<template>
  <div class="header">
    <div class="nav">
      <div id="navProducts">
        <router-link to="/products">Products</router-link>
      </div>
      <div id="navGames">
        <router-link to="/games">Games</router-link>
      </div>
      <div id="navLogo">
        <router-link to="/">
          <img :src="imagesUrl + 'trackir-by-optitrack-logo.svg'" alt="TrackIR" />
        </router-link>
      </div>
      <div id="navDevelopers">
        <router-link to="/developers">Developers</router-link>
      </div>
      <div id="navHelp">
        <router-link to="/help">Help</router-link>
      </div>
      <div id="navCart">
        <router-link to="/cart">
          <span class="icon-basket"></span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { IMAGES_URL } from "@/constants/";
export default {
  name: "Header",
  computed: {
    imagesUrl: function () {
      return IMAGES_URL;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/_variables.scss";
.header {
  height: $header-height-tiny;
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.35);
  flex-shrink: 0;
  z-index: 500;

  @include for-medium-up {
    height: $header-height;
  }
}
.nav {
  padding: 10px 0 8px;
  @include adaptiveContainer();
  display: grid;

  grid-template-columns: 1fr 1fr 1fr 1fr 25px;
  grid-template-rows: 1fr 1fr;

  #navProducts {
    grid-row: 2;
    grid-column: 1;
    text-align: center;
  }

  #navGames {
    grid-row: 2;
    grid-column: 2;
    text-align: center;
  }
  #navLogo {
    grid-column: 1 / span 5;
    grid-row: 1;
    text-align: center;

    img {
      width: 144px;
      height: auto;
    }
  }
  #navDevelopers {
    grid-row: 2;
    grid-column: 3;
    text-align: center;
  }
  #navHelp {
    grid-row: 2;
    grid-column: 4;
    text-align: center;
  }
  #navCart {
    grid-column: 5;
    grid-row: 2;
  }

  @include for-medium-up {
    padding: 26px 0 22px;
    grid-template-columns: 1fr 1fr 250px 1fr 1fr 25px;
    grid-template-rows: 1fr;

    #navProducts {
      grid-row: 1;
      grid-column: 1;
    }

    #navGames {
      grid-row: 1;
      grid-column: 2;
      a {
        margin-left: 25px;
      }
    }
    #navLogo {
      grid-row: 1;
      grid-column: 3 / span 1;
      a {
        margin-left: 40px;
      }
      img {
        width: 207px;
      }
    }
    #navDevelopers {
      grid-row: 1;
      grid-column: 4;
      a {
        margin-left: 38px;
      }
    }
    #navHelp {
      grid-row: 1;
      grid-column: 5;
      a {
        margin-left: 38px;
      }
    }
    #navCart {
      grid-row: 1;
      grid-column: 6;
      a {
        margin-left: 7px;
      }
    }
  }

  a {
    text-decoration: none;
    color: var(--color-text-default);
    font-size: $font_size_small;

    i {
      font-size: 13px;
      color: var(--color-text-default);
      position: relative;
      top: 2px;
    }

    img {
      height: 15px;
      max-width: 100%;
    }
  }
}
</style>
