import Vue from "vue";
import * as types from "../../mutation-types";

let cart = window.localStorage.getItem("cart");

const state = {
  store_name: "gaming",
  current_cart: cart ? JSON.parse(cart) : null,
  loading: false,
  coupon_hash: ""
};

const getters = {
  cartCount: state => {
    return state.current_cart.total_item_count;
  },
  cartTotal: state => {
    state.current_cart.total_price;
  }
};

const mutations = {
  [types.SET_CART](state, cart) {
    state.current_cart = cart;
    window.localStorage.setItem("cart", JSON.stringify(state.current_cart));
  },
  [types.TOGGLE_LOADING](state) {
    state.loading = !state.loading;
  },
  [types.COUPON_HASH](state, coupon_hash) {
    state.current_cart.coupon_hash = coupon_hash;
  }
};

const actions = {
  newCart: ({ commit, dispatch }, forceToCreate = false) => {
    return new Promise((resolve, reject) => {
      if (!state.current_cart || forceToCreate) {
        commit(types.TOGGLE_LOADING);
        Vue.axios
          .get("cart/" + state.store_name + "/new")
          .then(response => {
            commit(types.SET_CART, response.data)
          }).catch(function (r) {
            //console.log(r);
            reject(r);
          })
          .then(() => {
            commit(types.TOGGLE_LOADING);
            resolve();
          });
      }
      resolve();
    });
  },
  loadCart: ({ commit, dispatch }, cartId) => {
    return new Promise((resolve, reject) => {
      if (state.current_cart.id < 0) {
        commit(types.TOGGLE_LOADING);
        Vue.axios
          .get("cart/" + state.store_name + "/" + cartId)
          .then(response => commit(types.SET_CART, response.data))
          .catch(function (r) {
            //console.log(r);
            reject(r);
          })
          .then(() => {
            commit(types.TOGGLE_LOADING);
            resolve();
          });
      }
      resolve();
    });
  },
  addToCartAction: ({ commit, dispatch }, cartItem) => {
    dispatch("newCart").then(() => {
      if (cartItem && state.current_cart) {
        var item = {
          cart_id: state.current_cart.id,
          product_id: cartItem.product_id,
          thumbnail: cartItem.thumbnail,
          quantity: cartItem.quantity,
          options: []
        };
        if (cartItem.options) {
          for (var poption in cartItem.options) {
            item.options.push({
              product_id: cartItem.product_id,
              thumbnail: cartItem.thumbnail,
              group_id: poption.group_id,
              item_id: poption.item_id,
              price: poption.price
            });
          }
        }
        Vue.axios
          .post(
            "cart/" +
            state.store_name +
            "/" +
            state.current_cart.id +
            "/item",
            item
          )
          .then(response => {
            commit(types.SET_CART, response.data)
            dispatch("calcTotals", null, { root: true });
          })
          .catch(function (r) {
            //console.log(r);
          });
      }
    });
  },
  addBundleToCartAction: ({ commit, dispatch }, payload) => {
    Vue.axios
      .post(
        "cart/" +
        state.store_name +
        "/" +
        state.current_cart.id +
        "/bundle/" +
        payload.hash + "/" + payload.quanity
      ).then(response => {
        commit(types.SET_CART, response.data)
        dispatch("calcTotals", null, { root: true });
      })
      .catch(function (r) {
        //console.log(r);
      });
  },
  addPromoToCartAction: ({ commit, dispatch }, promo) => {
    return new Promise((resolve, reject) => {
      Vue.axios
        .post("cart/" + state.store_name + "/" + state.current_cart.id + "/bundle/" + promo)
        .then(response => {
          commit(types.SET_CART, response.data)
        })
        .catch(function (r) {
          reject(r);
        })
        .then(() => {
          //commit(types.TOGGLE_LOADING);
          dispatch("calcTotals", null, { root: true });
          resolve();
        });
    })
  },
  removeCartAction: ({ commit, dispatch }, cartItem) => {
    if (cartItem) {
      Vue.axios
        .delete(
          "cart/" +
          state.store_name +
          "/" +
          state.current_cart.id +
          "/item/" +
          cartItem.id
        ).then(response => {
          commit(types.SET_CART, response.data)
          dispatch("calcTotals", null, { root: true });
        })
        .catch(function (r) {
          //console.log(r);
        });
    }
  },
  updateQuantityAction: ({ commit, dispatch }, data) => {
    if (data) {
      var url =
        "cart/" +
        state.store_name +
        "/" +
        state.current_cart.id +
        "/" +
        data.itemId +
        "/" +
        data.newQuantity;
      Vue.axios
        .post(url)
        .then(response => {
          commit(types.SET_CART, response.data)
          dispatch("calcTotals", null, { root: true });
        })
        .catch(function (r) {
          //console.log(r);
        });
    }
  },
  clearCart: ({ commit, dispatch }) => {
    Vue.axios
      .delete(
        "cart/" +
        state.store_name +
        "/" +
        state.current_cart.id +
        "/remove"
      ).then(response => {
        commit(types.SET_CART, response.data)
        dispatch("calcTotals", null, { root: true });
      })
      .catch(function (r) {
        //console.log(r);
      });
  },
  applyCoupon: ({ commit, dispatch }) => {
    Vue.axios
      .delete(
        `cart/${state.store_name}/${state.current_cart.id}/apply/${state.current_cart.coupon_hash}`
      ).then(response => {
        commit(types.SET_CART, response.data);
        dispatch("calcTotals", null, { root: true });
      }).catch((err) => {
        // console.log(err);
      });
  }
};

const module = {
  state,
  getters,
  mutations,
  actions
};

export default module;
