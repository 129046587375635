import * as types from "../../mutation-types";
import Vue from "vue";
import _ from 'lodash'

const state = {
  formData: {
    name: null,
    email: null,
    phone: null,
    subject: null,
    order: null,
    serial: null,
    description: null,
    ip:null,
    captcha:null
  }
};


const mutations = {
  [types.SET_CONTACT_NAME](state, data) {
    state.formData.name = data;
  },
  [types.SET_CONTACT_EMAIL](state, data) {
    state.formData.email = data;
  },
  [types.SET_CONTACT_PHONE](state, data) {
    state.formData.phone = data;
  },
  [types.SET_CONTACT_SUBJECT](state, data) {
    state.formData.subject = data;
  },
  [types.SET_CONTACT_ORDER](state, data) {
    state.formData.order = data;
  },
  [types.SET_CONTACT_SERIAL](state, data) {
    state.formData.serial = data;
  },
  [types.SET_CONTACT_DESCRIPTION](state, data) {
    state.formData.description = data;
  },
  [types.SET_CONTACT_IP](state, data) {
    state.formData.ip = data;
  },
  [types.SET_CONTACT_CAPTCHA](state, data) {
    state.formData.captcha = data;
  },
  [types.SET_CONTACT_SUMMARY](state, details) {
    state.contactSummary = details;
  },
  [types.SET_CONTACT_FORM](state) {
    state.formData.name = null;
    state.formData.email = null;
    state.formData.phone = null;
    state.formData.subject = null;
    state.formData.order = null;
    state.formData.serial = null;
    state.formData.description = null;
    state.formData.ip = null;
    state.formData.captcha = null;
  }
};

const actions = {
  sendContactForm: (dispatch) => {
    return new Promise((resolve, reject) => {

      //convert to url params
      const params = new URLSearchParams();
      params.append('name', dispatch.state.formData.name);
      params.append('email', dispatch.state.formData.email);
      params.append('phone', dispatch.state.formData.phone);
      params.append('subject', dispatch.state.formData.subject);
      params.append('order', dispatch.state.formData.order);
      params.append('serial', dispatch.state.formData.serial);
      params.append('description', dispatch.state.formData.description);
      params.append('ip', dispatch.state.formData.ip);
      params.append('site', 'trackir');
      params.append('g-recaptcha-response', dispatch.state.formData.captcha);

      //send as urlencoded data
      const config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
      Vue.axios
        .post(
            "support/ticket/",
            params,
            config
        )
        .then(response => dispatch.commit(types.SET_CONTACT_SUMMARY, response.data))
        .catch(function(r) {
            //console.log(r);
            reject();
        })
        .then(() => {
            dispatch.commit(types.SET_CONTACT_FORM);
            resolve();
        });
    });
  }
};


const module = {
  state,
  actions,
  mutations
};

export default module;