import Vue from "vue";
import Vuex from "vuex";
import createLogger from "vuex/dist/logger";
import cart from "./modules/cart";
import game from "./modules/game";
import contact from "./modules/contact";
import checkout from "./modules/checkout";
import createPersist from "vuex-localstorage";

Vue.use(Vuex);

//const debug = process.env.NODE_ENV !== "production";
const debug = false;


var plugins = [];

if (debug) {
  plugins.push(createLogger());
} else {
  // only use this in prod
  plugins.push(
    createPersist({
      namespace: "trackir",
      initialState: {},
      // ONE_WEEK
      expires: 7 * 24 * 60 * 60 * 1e3
    })
  );
}
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    cart,
    game,
    contact,
    checkout
  },
  strict: debug,
  plugins: plugins
});
