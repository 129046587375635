<template>
  <div class="footer">
    <div class="contentWrapper">
      <ul id="supportUL">
        <li>Support</li>
        <li>
          <a href="https://forums.naturalpoint.com/viewforum.php?f=72"
            >Forums</a
          >
        </li>
        <li>
          <router-link to="/downloads">Downloads</router-link>
        </li>
      </ul>
      <ul id="aboutUL">
        <li>
          <router-link to="/about">About</router-link>
        </li>
        <li>
          <router-link to="/dealers">Dealers</router-link>
        </li>
        <li>
          <router-link to="/help/#contact">Contact Us</router-link>
        </li>
        <li>
          <router-link to="/media">Media</router-link>
        </li>
        <li>
          <a href="https://www.naturalpoint.com/careers/" target="_blank">Careers</a>
        </li>
      </ul>
      <ul id="policiesUL">
        <li>Policies</li>
        <li>
          <router-link to="/accessibility">Accessibility</router-link>
        </li>
        <li>
          <router-link to="/warranty">Warranty &amp; Returns</router-link>
        </li>
        <li>
          <router-link to="/privacy">Privacy</router-link>
        </li>
        <li>
          <router-link to="/terms">Terms of Sale</router-link>
        </li>
        <li>
          <router-link to="/eula">EULA</router-link> | <router-link to="/sdkeula">SDK EULA</router-link>
        </li>

      </ul>
      <section id="socialLinks">
        <div class="socialIcons">
          <a
            id="twitchLink"
            target="_blank"
            href="http://www.twitch.tv/trackir_official"
          >
            <span class="icon-twitch"></span>
          </a>

          <a
            id="twitterLink"
            target="_blank"
            href="https://twitter.com/trackir"
          >
            <span class="icon-twitter"></span>
          </a>

          <a
            id="facebookLink"
            target="_blank"
            href="https://www.facebook.com/TrackirOfficial/"
          >
            <span class="icon-facebook"></span>
          </a>
        </div>
        <!--END socialIcons-->
        <div class="copyright">
          &copy; {{ new Date().getFullYear() }} NaturalPoint, Inc. DBA OptiTrack
        </div>
      </section>
    </div>
    <!-- END contentWrapper -->
  </div>
  <!-- END footer -->
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/_variables.scss";

.footer {
  background: var(--color-bg-dark);
  font-size: 13px;
  padding-top: 50px;
  box-sizing: border-box;
  flex-shrink: 0;
  height: 375px;

  @include for-medium-up {
    height: 246px;
  }

  .contentWrapper {
    max-width: $tiny-width;
    margin-left: auto;
    margin-right: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    color: var(--color-text-extradim);

    @include for-medium-up {
      max-width: $medium-width;
    }

    #supportUL {
      grid-column: 1;
      grid-row: 1;
    }
    #aboutUL {
      grid-column: 2;
      grid-row: 1;
    }
    #policiesUL {
      grid-column: 3;
      grid-row: 1;
    }

    #socialLinks {
      grid-column: 1 / span 3;
      grid-row: 2;
      font-size: 24px;
      text-align: center;
      padding-top: 20px;

      @include for-medium-up {
        grid-column: 4;
        grid-row: 1;
        text-align: right;
        padding-top: 0px;

        a {
          margin-left: 10px;
        }
      }

      a {
        margin: 0 5px;
      }
      .copyright {
        margin-top: 10px;
        font-size: 13px;
      }
    }

    a {
      text-decoration: none;
      color: var(--color-text-extradim);
      transition: 0.3s;

      &:hover {
        color: #ffffff;
      }
    }

    ul {
      margin: 0px;
      padding: 0px;

      li {
        list-style: none;
        padding: 0px;
        margin: 0px 0px 6px;
        line-height: 18px;
        white-space: nowrap;

        &:first-child,
        &:first-child a {
          color: #ffffff;
          font-weight: bold;
          font-size: 13px;
        }
      }
    }
    #sole {
      font-size: 11px;
      border-top: 1px solid var(--color-text-extradim);
      margin-top: 26px;
      padding-top: 20px;
      grid-column: 1 / span 3;
      grid-row: 3;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: auto;
      text-align: center;

      div {
        margin-bottom: 5px;
      }

      :nth-child(1) {
        grid-column: 1;
        grid-row: 1;
      }
      :nth-child(2) {
        grid-column: 1;
        grid-row: 2;
      }
      :nth-child(3) {
        grid-column: 1;
        grid-row: 3;
      }
      :nth-child(4) {
        grid-column: 1;
        grid-row: 4;
      }

      @include for-medium-up {
        margin-top: 46px;
        grid-column: 1 / span 4;
        grid-row: 2;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: auto;

        div {
          margin-bottom: 0px;
        }

        :nth-child(1) {
          grid-column: 1;
          grid-row: 1;
          text-align: left;
        }
        :nth-child(2) {
          grid-column: 2;
          grid-row: 1;
        }
        :nth-child(3) {
          grid-column: 3;
          grid-row: 1;
        }
        :nth-child(4) {
          grid-column: 4;
          grid-row: 1;
        }
      }

      a {
        color: #ffffff;
      }

      .copyright {
        margin-top: 20px;
        text-align: center;

        @include for-medium-up {
          text-align: right;
          margin-top: 0px;
        }
      }
    }

    @include for-medium-up {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-rows: auto;
    }
  }
}
</style>
