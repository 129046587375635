import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueAxios from "vue-axios";

Vue.config.productionTip = false;

Vue.use(VueAxios, axios);
Vue.axios.defaults.baseURL = process.env.VUE_APP_API_ROOT;


if (!Vue.axios.defaults.baseURL) {
  var apiRoot = document.querySelector("#root").getAttribute("apiroot");
  Vue.axios.defaults.baseURL = apiRoot;
}

new Vue({
  router,
  store,
  render: h => h(App),
  created() {
    //initialize store data structure by submitting action.
    this.$store.dispatch("newCart");
  }
}).$mount("#app");
