import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      name: "home",
      meta: { title: "Home | TrackIR" },
      component: () => import("./views/Home.vue")
    },
    {
      path: "/about",
      name: "about",
      meta: { title: "About | TrackIR" },
      component: () => import("./views/About.vue")
    },
    {
      path: "/accessories",
      name: "accessories",
      meta: { title: "Accessories | TrackIR" },
      component: () => import("./views/Accessories.vue")
    },
    {
      path: "/cart",
      name: "cart",
      meta: { title: "Cart | TrackIR" },
      component: () => import("./views/Cart.vue")
    },
    {
      path: "/checkout",
      name: "checkout",
      meta: { title: "Checkout | TrackIR" },
      component: () => import("./views/Checkout.vue")
    },
    {
      path: "/promo/:id",
      name: "promo",
      meta: { title: "Promo | TrackIR" },
      component: () => import("./views/Promo.vue")
    },
    {
      path: "/dealers",
      name: "dealers",
      meta: { title: "Dealers | TrackIR" },
      component: () => import("./views/Dealers.vue")
    },
    {
      path: "/developers",
      name: "developers",
      meta: { title: "Developers | TrackIR" },
      component: () => import("./views/Developers.vue")
    },
    {
      path: "/download",
      redirect: {name:'downloads'}
    },
    {
      path: "/downloads",
      name: "downloads",
      meta: { title: "Downloads | TrackIR" },
      component: () => import("./views/Downloads.vue")
    },
    {
      path: "/eula",
      name: "eula",
      meta: { title: "EULA | TrackIR" },
      component: () => import("./views/Eula.vue")
    },
    {
      path: "/games",
      name: "games",
      meta: { title: "Games List | TrackIR" },
      component: () => import("./views/Games.vue")
    },
    {
      path: "/help",
      name: "help",
      meta: { title: "Help | TrackIR" },
      component: () => import("./views/Help.vue")
    },
    {
      path: "/media",
      name: "media",
      meta: { title: "Media Kit | TrackIR" },
      component: () => import("./views/Media.vue")
    },
    {
      path: "/privacy",
      name: "privacy",
      meta: { title: "Privacy Policy | TrackIR" },
      component: () => import("./views/Privacy.vue")
    },
    {
      path: "/products",
      name: "products",
      meta: { title: "Products | TrackIR" },
      component: () => import("./views/Products.vue")
    },
    {
      path: "/sdkeula",
      name: "sdkeula",
      meta: { title: "SDK EULA | TrackIR" },
      component: () => import("./views/SdkEula.vue")
    },
    {
      path: "/support",
      redirect: {name:'help'}
    },
    {
      path: "/terms",
      name: "terms",
      meta: { title: "Terms of Use | TrackIR" },
      component: () => import("./views/Terms.vue")
    },
    {
      path: "/trackir5",
      name: "trackir5",
      meta: { title: "TrackIR5 - In Depth | TrackIR" },
      component: () => import("./views/TrackIR5.vue")
    },
    {
      path: "/warranty",
      alias: "/warranty-return-policy",
      name: "warranty",
      meta: { title: "Warranty & Returns | TrackIR" },
      component: () => import("./views/Warranty.vue")
    },
    {
      path: "/accessibility",
      alias: "/accessibility-policy",
      name: "accessibility",
      meta: { title: "Accessibility | TrackIR" },
      component: () => import("./views/Accessibility.vue")
    },
    {
      path:"/404",
      name:"404",
      meta: {title: "Not Found | TrackIR"},
      component: () => import("./views/NotFound.vue")
    },
    {
      path: "*",
      redirect: {name:'404'}
    }
  ]
});

/* Default page title */
const DEFAULT_TITLE = "TrackIR";

/* Change page title */
router.afterEach(to => {
  document.title = to.meta.title || DEFAULT_TITLE;
});

export default router;
