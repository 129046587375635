import * as types from "../../mutation-types";
import Vue from "vue";
import _ from 'lodash'

const initialTotals = {
    cart_total: 0,
    shipping:0,
    tax:0,
    discount:0,
    total:0
};

const initialCheckoutData = {
    order_id: 0,
    cart_id: 1,
    session_id: "",
    total: 0,
    po_number: null,
    rep_id: 1,
    sale_credit_rep_id: 1,
    partner_id: 14,
    store_name: "gaming",
    order_channel: "webstore",
    customer_type: "end_user",
    market_segment: "general_use",
    payment_terms: "pre-pay",
    creator: "",
    order_type: "sale",
    ship_with_parent_order_id: 0,
    shipping: {
        cart_id: null,
        contact: {
            first_name: "",
            last_name: "",
            phone: "",
            email: "",
            organization: ""
        },
        address: {
            attention: "",
            address_1: "",
            address_2: "",
            address_3: "",
            city: "",
            state_prov: "",
            zip_code: "",
            country: "",
            zip_4: "0001"
        },
        shipping_weight: null,
        shipping_tax_id_number: "",
        is_dropship: false,
        selected_rate: {
            provider: "",
            code: "",
            vendor_code: "",
            description: "",
            price: null
        }
    },
    billing: {
        cart_id: 0,
        contact: {
            first_name: "",
            last_name: "",
            phone: "",
            email: "",
            organization: ""
        },
        address: {
            attention: "",
            address_1: "",
            address_2: "",
            address_3: "",
            city: "",
            state_prov: "",
            zip_code: "",
            country: "",
            zip_4: "0001"
        },
        currency: "USD",
        credit_card: {
            type: "V",
            number: "",
            expiration_month: "",
            expiration_year: "",
            ccv: ""
        }
    },
    tax: {}
};

const state = {
    destinationDetails: null,
    checkoutSummary: null,
    selectedShippingCode:null,
    totals: JSON.parse(JSON.stringify(initialTotals)),
    checkoutData: JSON.parse(JSON.stringify(initialCheckoutData))
};

const getters = {
    totals: state => {
        return state.totals;
    }
};

const mutations = {
    [types.SET_CHECKOUT](state) {
        state.destinationDetails = null;
        state.selectedShippingCode = null;
        state.totals = JSON.parse(JSON.stringify(initialTotals));
        state.checkoutData = JSON.parse(JSON.stringify(initialCheckoutData));
    },
    [types.SET_DESTINATION_DETAILS](state, details) {
        state.destinationDetails = details;
    },
    [types.SET_CHECKOUT_SUMMARY](state, details) {
        state.checkoutSummary = details;
    },
    [types.SET_CHECKOUT_FIELD] (state, formData){
        _.set(state.checkoutData, formData.field, formData.value)
    },
    [types.SET_TOTAL_FIELD] (state, formData){
        _.set(state.totals, formData.field, formData.value)
    },
    [types.SET_CART_ID](state, cartId){
        state.checkoutData.cart_id = cartId;
        state.checkoutData.shipping.cart_id = cartId;
        state.checkoutData.billing.cart_id = cartId;
    }
};

const actions = {
    calcTotals: (dispatch) => {

        var total = dispatch.rootState.cart.current_cart.total_price;
        var coupon_discount =  dispatch.rootState.cart.current_cart.coupon_discount;
        var taxableAmount = 0;
        var shippingCost = 0;
        dispatch.commit(types.SET_TOTAL_FIELD, {'field': 'discount', 'value':  coupon_discount});
        dispatch.commit(types.SET_TOTAL_FIELD, {'field': 'cart_total', 'value': total + coupon_discount});
        if(dispatch.state.checkoutData.shipping.selected_rate )  {
            shippingCost = dispatch.state.checkoutData.shipping.selected_rate.price;
            total += shippingCost;
            dispatch.commit(types.SET_TOTAL_FIELD, {'field': 'shipping', 'value': shippingCost});
        }
        
        //check if this state has sales tax
        if (dispatch.state.destinationDetails && dispatch.state.destinationDetails.taxInfo && dispatch.state.destinationDetails.taxInfo.stateTaxable) {

            //Set the initial taxable amount
            taxableAmount = dispatch.state.destinationDetails.taxInfo.taxableAmount;
            

            // check if this state taxes shipping
            if (dispatch.state.destinationDetails.taxInfo.shippingTaxable) {
                taxableAmount += shippingCost;
            }

            var taxTotal = taxableAmount * dispatch.state.destinationDetails.taxInfo.taxRate;

            dispatch.commit(types.SET_TOTAL_FIELD, {'field': 'tax', 'value': taxTotal});
            total += taxTotal;
        }else{
            //No Tax
            dispatch.commit(types.SET_TOTAL_FIELD, {'field': 'tax', 'value':0});
        }
        dispatch.commit(types.SET_TOTAL_FIELD, {'field': 'total', 'value': total});
    },
    getDestinationDetails:  (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch.commit(types.SET_CART_ID, dispatch.rootState.cart.current_cart.id);
            
            let shipping = dispatch.state.checkoutData.shipping
            
            //destination_details endpoint requires session_id
            shipping.session_id = dispatch.rootState.cart.current_cart.session_id;

            Vue.axios
                .post(
                    "checkout/destination_details",
                    shipping
                )
                .then(response => dispatch.commit(types.SET_DESTINATION_DETAILS, response.data))
                .catch(function(r) {
                    reject()
                })
                .then(() => {
                    resolve();
                });
            });
    },
    checkout: (context) => {
        return new Promise((resolve, reject) => {
            Vue.axios
                .post(
                    "checkout/place_order/",
                    context.state.checkoutData,
                    { timeout: 120000 } //timeout after 2 minutes 120000
                )
                .then((response) => {
                    const email = context.state.checkoutData.billing.contact.email;
                    context.commit(types.SET_CHECKOUT_SUMMARY, {
                        ...response.data,
                        email
                    });
                    resolve();
                })
                .catch(function(error) {
                    let errorResponse = error;
                    context.commit(types.SET_CHECKOUT_SUMMARY, {
                        ...context.state.checkoutSummary,
                        errorResponse
                    })

                    //reject();
                })
                .then(() => {
                    if (context.state.checkoutSummary.order_placed) {
                        context.commit(types.SET_CHECKOUT);
                        context.dispatch('newCart', true);
                    }
                    resolve();
                });
        });
    }
};

const module = {
    state,
    getters,
    actions,
    mutations
};

export default module;